<template>
    <div class="no-data">
        <img src="../../assets/img/no-data.png">
        <div class="no-data-txt">{{ txt }}</div>
    </div>
</template>

<script>
    export default {
        props: {
            txt: {
                type: String,
                default: '暂无相关内容~'
            }
        }
    }
</script>

<style scoped lang="scss">
    .no-data{
        text-align: center;
        padding: 100px;
        width: 100%;
        box-sizing: border-box;
        img{
            width: 200px;
        }
        .no-data-txt{
            margin-top: 15px;
            font-size: 20px;
            color: #666;
        }
    }
</style>
