<template>
    <div class="course-item">
        <div class="course-item-img">
			
            
			<!-- <img class="course-item-img-sign" :src="item.live_state==2?'https://image.ysxxlm.cn/ysxxlm/pc/static/img/live/1@2x.png':item.live_state==1?'https://image.ysxxlm.cn/ysxxlm/pc/static/img/live/2@2x.png':'https://image.ysxxlm.cn/ysxxlm/pc/static/img/live/3@2x.png'"/> -->
            <div class="live-list-item-label">
				<div class="label-icon">
					<i class="iconfont icon-play"></i> {{ item.live_state == 2?'正在直播':item.live_state==1?'等待直播':item.live_state == 4?'直播回放':item.live_state == 3?'直播已结束':'' }}
				</div>
            </div>
			<img class="course-item-img-master" :src="item.image"/>
        </div>
        <div class="course-item-title">{{ item.title }}</div>
    </div>
</template>

<script>
    export default {
        props: ['item']
    }
</script>

<style scoped lang="scss">
    .course-item{
        width: 210px;
        padding: 15px 15px 0;
        .course-item-img{
            height: 118px;
            vertical-align: top;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            .course-item-img-sign{
                position: absolute;
                left: 0;
                top: 0;
                width: 90px;
                height: 28px;
            }
			.live-list-item-label{
			    position: absolute;
			    top: 0;
			    left: 0;
			    img{
			        height: 28px;
			        width: auto;
			    }
			    .label-icon{
			        height: 28px;
			        line-height: 26px;
			        padding: 0 15px;
			        background: #288cfb;
			        color: #fff;
			        font-size: 14px;
			        border-bottom-right-radius: 5px;
			        i{
			            font-size: 12px;
			        }
			    }
			}
            .course-item-img-master{
                height: 100%;
                width: 100%;
                vertical-align: top;
            }
        }
        .course-item-title{
            color: #090B11;
            font-size: 16px;
            height: 45px;
            line-height: 45px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            vertical-align: top;
        }
        &:hover{
            box-shadow: 0 0 10px #ccc;
            border-radius: 5px;
            cursor: pointer;
        }
    }
</style>
