import Vue from 'vue';
import Vuex from 'vuex';
import config from '@/api/url';
import http from '@/api/request';
import api from '@/api/index';
import router from "../router";

import { is_wx,urlDelP } from '@/utils/index';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginDialog: false,
    uuid: localStorage.getItem('uuid'),
    uid: localStorage.getItem('uid'),
	token: localStorage.getItem('token'),
    userInfo: localStorage.getItem('userInfo') != ''&& localStorage.getItem('userInfo') != undefined?JSON.parse(localStorage.getItem('userInfo')):'',
    about: {
      intro: `
      <p>湖南优安医学科技有限公司是一家基于“互联网+医疗”的综合医学服务机构，专注于泌尿男科领域。依托泌尿外科医生学习联盟强大的学术资源，以赋能泌尿男科医护为核心，推动泌尿男科产业发展，助力健康中国2030。</p>
      <p>泌尿外科医生学习联盟是集专科医生学习，培训和交流为一体的综合平台，基本实现了全国泌尿外科医生的全覆盖...</p>
      `,
      followersNum: {
        //num: '80000+',
        num: 100000,
        text: '微信订阅号"泌尿科那点事儿"订阅人数'
      },
      followersRatio: {
        //num: '90%+',
        num: 90,
        text: '全国90%+以上泌尿外科医生关注'
      },
      source: {
        //num: '97%+',
        num: 97,
        text: '97%的平台医生来自二级和三级公立医院'
      },
      articleNum: {
        //num: '2500+',
        num: 3000,
        text: '篇高质量原创学术内容'
      }, // 高质量原创学术内容篇数
      session: {
        num: 1200,
        text: '场线上免费学术公开课'
      }, // 公开课数
      groupNum: {
        // num: '400+',
        num: 400,
        text: '泌外医生活跃社群'
      },
      coupeNum: {
        num: 300,
        text: '医学科普动画视频'
      }
    },
	// 默认网站关键词
	metaInfo: {
		title:'',
		keywords:'',
		description:''
	}
  },
  mutations: {
    wxLogin(state, boot) { // 登录二维码
      state.loginDialog = boot;
      if(boot) {
        setTimeout(function () {
          var obj = new WxLogin({
            id: 'wx_login_id', // 需要显示的容器id
            appid: config.Appid, // 公众号appid
            scope: 'snsapi_login', // 网页默认即可
            response_type: 'code',
			redirect_uri: encodeURIComponent('https://www.ysxxlm.com/#/'+location.href.split("?")[0].split("/#/")[1]), // 授权成功后回调的url
            // redirect_uri: encodeURIComponent(location.href.split("?")[0]), // 授权成功后回调的url
            // redirect_uri: encodeURIComponent(config.baseUrl+'/api/Wechat/weChatAuthorization4?state='+url), // 授权成功后回调的url
          })
        },100)
      }
    },
    logOut(state) {
      localStorage.setItem('userInfo', '')
      localStorage.setItem('uid', '')
	  localStorage.setItem('token','')
      state.userInfo = ''
      state.uid = ''
	  state.token = ''
      router.push('/');
    },
	CAHNGE_META_INFO(state, metaInfo) {
		console.log(metaInfo,"metaInfo")
		state.metaInfo = metaInfo;
	}
  },
  actions: {
    toLogin({dispatch,state},code){
      if(is_wx()) {//微信浏览器
        http.post(api.wx_Login, {code}).then(res => {
          if(res.code == 1) {
            state.userInfo = res.data;
            state.uid = res.data.user.user_id
            localStorage.setItem('userInfo',JSON.stringify(res.data))
            localStorage.setItem('uid',res.data.user.user_id)
            location.reload();
          }
        })
      }else {
        http.post(api.Login, {code,uuid:state.uuid}).then(res => {
			if(res.code==1){
				state.token=res.data
				localStorage.setItem('token',res.data)
			}
          dispatch('LxUuid')
        })
      }
    },
    pcUuid({ dispatch, state}) {
      http.post(api.pcUuid).then(res => {
        state.uuid = res.msg;
        localStorage.setItem('uuid',res.msg);
        dispatch('LxUuid', res.msg);
      })
    },
    LxUuid({ dispatch,commit, state }, code) {
      http.post(api.LxUuid, {
        uuid:state.uuid
      }, {
        loading: false
      }).then(res => {
        if(res.code == 2) {
          setTimeout(()=> {
            state.userInfo = '';
            state.uid = ''
            localStorage.setItem('userInfo','')
            localStorage.setItem('uid','')
            dispatch('LxUuid',state.code);
          },5000)
        }else {
          state.userInfo = res.data;
          state.uid = res.data.user.user_id
          localStorage.setItem('userInfo',JSON.stringify(res.data))
          localStorage.setItem('uid',res.data.user.user_id)
          commit('wxLogin',false)
        }
      })
    }
  },
  modules: {
  }
})
