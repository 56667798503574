import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/neat.css'
import './assets/css/app.scss'
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	// 设置页面标题
	// if(to.query.title){to.meta.title = to.query.title}
	// document.title = to.meta.title;
	document.getElementsByTagName("title")[0].innerText = to.meta.title;
	  if (to.meta.metaInfo){
	    store.commit("CAHNGE_META_INFO", to.meta.metaInfo)
	  }else{
		  store.commit("CAHNGE_META_INFO", to)
	  }
	next()
})

import 'swiper/dist/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI,{ zIndex: 30000 });
import globalComponents from "./components";
Vue.use(globalComponents);
import Meta from 'vue-meta';
Vue.use(Meta);
// 导入插件
import request from './api/request'
// 在原型上扩展,这样不用在每个页面都导入request
Vue.prototype.$axios = request;
import apiUrl from './api/index';
Vue.prototype.apiUrl = apiUrl;
new Vue({
	router,
	store,
	metaInfo(){
		return {
			title: this.$store.state.metaInfo.title,
			meta: [
				{
					name: "keywords",
					content: this.$store.state.metaInfo.keywords
				}, {
					name: "description",
					content: this.$store.state.metaInfo.description
				}
			]
		}
	},
	render: function (h) { return h(App) }
}).$mount('#app')
