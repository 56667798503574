<template>
    <div class="menu-content">
        <div class="menu-main">
            <div class="menu-item" v-for="(item,index) in tagList" :key="index" v-if="index < 3">
                <div class="menu-item-label">
                    {{ item.name }}：
                </div>
                <div class="menu-item-content">
                    <span :class="item.active == item.id?'on': ''" @click="emitTagsActive(index, item.id)">全部</span>
                    <span :class="item.active == label.id?'on': ''" v-for="(label, i) in item.subclass" :key="i" @click="emitTagsActive(index, label.id)">{{ label.name }}</span>
                </div>
            </div>
            <div class="menu-other">
                <div class="menu-other-label">其他：</div>
                <div class="menu-other-content">
                    <div class="menu-other-content-item" v-if="!isActciles">
                        <div class="menu-other-content-info">分类<i class="iconfont icon-xiala"></i></div>
                        <div class="menu-other-content-list">
                            <div class="menu-other-content-list-main">
                                <span :class="classOn == item.id?'on':''" v-for="(item,index) in classList" @click="emitActive(item.id)" :key="index">{{ item.cate_name }}</span>
                            </div>

                        </div>
                    </div>
                    <div class="menu-other-content-item" v-if="isLive">
                        <div class="menu-other-content-info">分类<i class="iconfont icon-xiala"></i></div>
                        <div class="menu-other-content-list">
                            <div class="menu-other-content-list-main">
                                <span :class="classOn == item.id?'on':''" v-for="(item,index) in classLiveList" @click="emitActive(item.id)" :key="index">{{ item.cate_name }}</span>
                            </div>

                        </div>
                    </div>
                    <div class="menu-other-content-item" v-for="(item,index) in tagList" :key="index" v-if="index>=3 && index<tagList.length">
                        <div class="menu-other-content-info">{{ item.name }}<i class="iconfont icon-xiala"></i></div>
                        <div class="menu-other-content-list">
                            <div class="menu-other-content-list-main">
                                <span :class="item.active == item.id?'on': ''" @click="emitTagsActive(index, item.id)">全部</span>
                                <span :class="item.active == label.id?'on': ''" v-for="(label, i) in item.subclass" :key="i" @click="emitTagsActive(index, label.id)">{{ label.name }}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isActciles: {
                type: Boolean,
                default: false
            },
            isLive: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tagList: [],
                classList: [],
                classOn: null,
                classLiveList: [{cate_name: '全部',id:3},{cate_name: '直播中',id:2},{cate_name: '已结束',id:1},{cate_name: '等待直播',id:0}]
            }
        },
        mounted() {
            this.getClassApi();
        },
        methods: {
            getClassApi() { // 分类 筛选
                this.$axios.post(this.apiUrl.classApi, {
                    type: 1
                }).then(res => {
                    this.classList = res.data;
                    this.classList.unshift({id: null,cate_name:'全部'})
                })
                this.$axios.post(this.apiUrl.tags, {
                    type: 1
                }).then(res => {
                    this.tagList = res.data;
                    this.tagList.map(res => {
                        res.active = res.id;
                    })
                })
            },
            emitActive(i) {
                this.classOn = i;
                this.$emit('onActive', i)
            },
            emitTagsActive(index,i) {
                this.$set(this.tagList[index], 'active', i)
                this.$forceUpdate();
                let tags = [];
                this.tagList.map(item => {
                    if(item.id != item.active) {
                        tags.push(item.active)
                    }
                })
                this.$emit('onTagsActive',tags)
            }
        }
    }
</script>

<style scoped lang="scss">
    .menu-content{
        box-shadow: 0 0 10px #ccc;
        background: #fff;
        .menu-main{
            width: 1200px;
            min-width: 1200px;
            margin: 0 auto;
            font-size: 16px;
            .menu-item{
                display: flex;
                padding: 30px 0 25px;
                border-bottom: 1px solid #eee;
                .menu-item-label{
                    color: #A8A8A8;
                    width: 80px;
                }
                .menu-item-content{
                    flex: 1;
                    color: #000;
                    span {
                        padding: 0 14px;
                        height: 30px;
                        display: inline-block;
                        border-radius: 40px;
                        text-align: center;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        &:hover{
                            background: #288CFB;
                            color: #fff;
                        }
                        &.on {
                            background: #288CFB;
                            color: #fff;
                        }
                    }
                }
            }
            .menu-other{
                display: flex;
                padding: 30px 0 25px;
                position: relative;
                .menu-other-label{
                    color: #A8A8A8;
                    width: 80px;
                }
                .menu-other-content{
                    flex: 1;
                    color: #A8A8A8;
                    .menu-other-content-item{
                        display: inline-block;
                        cursor: pointer;
                        .menu-other-content-info{
                            margin-right: 20px;
                            height: 30px;
                            box-sizing: border-box;
                            border: 1px solid #ABABAB;
                            border-radius: 40px;
                            text-align: center;
                            padding: 0 12px;
                        }
                        .menu-other-content-list{
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 60px;
                            transition: .3s;
                            visibility: hidden;
                            opacity: 0;
                            z-index: 0;
                            padding-top: 15px;
                            .menu-other-content-list-main{
                                border-radius: 5px;
                                background: #fff;
                                padding: 20px;
                                box-shadow: 0 0 5px #ccc;
                            }
                            span {
                                padding: 0 14px;
                                height: 30px;
                                display: inline-block;
                                border-radius: 40px;
                                text-align: center;
                                margin-right: 5px;
                                margin-bottom: 5px;
                                cursor: pointer;
                                vertical-align: top;
                                &:hover{
                                    background: #288CFB;
                                    color: #fff;
                                }
                                &.on {
                                    background: #288CFB;
                                    color: #fff;
                                }
                            }
                        }
                        &:hover {
                            .menu-other-content-info{
                                border: 1px solid #288CFB;
                                color: #288CFB;
                            }
                            .menu-other-content-list{
                                top: 60px;
                                visibility: unset;
                                opacity: 1;
                                z-index: 9;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
