export function is_wx() {
    let ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    }else {
        return false
    }
}
export function urlDelP(url, name) {

    var urlArr = url.split('?');

    if (urlArr.length > 1 && urlArr[1].indexOf(name) > -1) {

        var query = urlArr[1];

        var obj = {}

        var arr = query.split("&");

        for (var i = 0; i < arr.length; i++) {

            arr[i] = arr[i].split("=");

            obj[arr[i][0]] = arr[i][1];

        };

        delete obj[name];

        var urlte = urlArr[0] + '?' + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(
            /\,/g, "&");

        return urlte;

    } else {

        return url;

    };

}
