<template>
    <div class="menu-content">
        <div class="menu-main">
            <div class="menu-item" v-for="(item,index) in tagList" :key="index">
                <div class="menu-item-label">{{ item.name }}：</div>
                <div class="menu-item-content">
                    <span :class="item.active == item.id?'on': ''" @click="emitTagsActive(index, item.id)">全部</span>
                    <span :class="item.active == label.id?'on': ''" v-for="(label, i) in item.subclass" :key="i" @click="emitTagsActive(index, label.id)">{{ label.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                tagList: [
					{
						id:0,
						active:0,
						name:'会员类型',
						key:'vip_type',
						subclass:[
							{id:3,name:'VVIP'},
							{id:1,name:'SVIP(腹腔镜)'},
							{id:2,name:'SVIP(非腹腔镜)'},
							{id:4,name:'VIP'},
							{id:5,name:'其他'}
						]
					},
					{
						id:0,
						active:0,
						name:'课程类型',
						key:'course_type',
						subclass:[
							{id:1,name:'专场课'},
							{id:2,name:'直播课'},
							{id:3,name:'公开课'}
						]
					}
					// ,{
					// 	id:0,
					// 	active:0,
					// 	name:'专业类型',
					// 	key:'professional_type',
					// 	subclass:[
					// 		{id:1,name:'腹腔镜手术'},
					// 		{id:2,name:'非腹腔镜手术'},
					// 	]
					// }
				]
            }
        },
        methods: {
            emitTagsActive(index,i) {
                this.$set(this.tagList[index], 'active', i);
                this.$forceUpdate();
                let tags = new Object();
				this.tagList.forEach(item=>{tags[item.key]=item.active});
				console.log('tags',tags);
				this.$emit('onTagsActive',tags)
            }
        }
    }
</script>
<style scoped lang="scss">
    .menu-content{
        box-shadow: 0 0 10px #ccc;
        background: #fff;
        .menu-main{
            width: 1200px;
            min-width: 1200px;
            margin: 0 auto;
            font-size: 16px;
            .menu-item{
                display: flex;
                padding: 30px 0 25px;
                border-bottom: 1px solid #eee;
                .menu-item-label{
                    color: #A8A8A8;
                    width: 80px;
                }
                .menu-item-content{
                    flex: 1;
                    color: #000;
                    span {
                        padding: 0 14px;
                        height: 30px;
                        display: inline-block;
                        border-radius: 40px;
                        text-align: center;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        &:hover{
                            background: #288CFB;
                            color: #fff;
                        }
                        &.on {
                            background: #288CFB;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>