import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: () => import('@/views/home'),
		meta: {
			title: '泌尿科那点事儿',
			// 泌尿外科医生学习联盟
		}
	},
	{ // 手术视频
		path: '/lct',
		component: () => import('@/views/lct/index'),
		meta: {
			title: '手术视频'
		}
	},
	{ // 手术视频详情
		path: '/lct/:id/detail',
		component: () => import('@/views/lct/detail'),
		meta: {
			title: '手术视频详情'
		}
	},
	{ // 手术视频播放
		path: '/lct/:id/play',
		component: () => import('@/views/lct/play'),
		meta: {
			title: '手术视频播放'
		}
	},
	{ // 手术视频播放
		path: '/fqj/:id/play',
		component: () => import('@/views/fqj/play'),
		meta: {
			title: '腹腔镜视频播放'
		}
	},
	{ // 培训课程
		path: '/course',
		component: () => import('@/views/course'),
		meta: {
			title: '培训课程'
		}
	},
	{ // 培训课程详情
		path: '/course/:id/detail',
		component: () => import('@/views/course/detail'),
		meta: {
			title: '付费课程详情',
			metaInfo: {
				title: "付费课程详情",
				keywords: "",
				description: ""
			}
		}
	},
	{ // 培训课程播放
		path: '/course/:id/play',
		component: () => import('@/views/course/play'),
		meta: {
			title: '付费课程播放'
		}
	},
	{ // 培训课程（会员课程）
		path: '/course_vip',
		component: () => import('@/views/course/vip'),
		meta: {
			title: '会员课程'
		}
	},
	{ // 会员课程
		path: '/courseVip',
		component: () => import('@/views/courseVip'),
		meta: {
			title: '会员课程'
		}
	},
	{ // 会员课程详情
		path: '/courseVip/:id/detail',
		component: () => import('@/views/courseVip/detail'),
		meta: {
			title: '会员课程详情',
			metaInfo: {
				title: "会员课程详情",
				keywords: "",
				description: ""
			}
		}
	},
	{ // 会员课程播放
		path: '/courseVip/:id/play',
		component: () => import('@/views/courseVip/play'),
		meta: {
			title: '会员课程播放'
		}
	},
	{ // 讲座会议
		path: '/seminars',
		component: () => import('@/views/seminars'),
		meta: {
			title: '讲座会议'
		}
	},
	{ // 讲座会议详情
		path: '/seminars/:id/detail',
		component: () => import('@/views/seminars/detail'),
		meta: {
			title: '讲座会议详情'
		}
	},
	{ // 讲座会议播放
		path: '/seminars/:id/play',
		component: () => import('@/views/seminars/play'),
		meta: {
			title: '讲座会议'
		}
	},
	{ // 讲座会议回放课程详情
		path: '/seminars/:id/details',
		component: () => import('@/views/seminars/details'),
		meta: {
			title: '讲座会议详情'
		}
	},
	{ // 讲座会议回放课程播放
		path: '/seminars/:id/plays',
		component: () => import('@/views/seminars/plays'),
		meta: {
			title: '讲座会议'
		}
	},
	{ // 资讯
		path: '/actciles',
		component: () => import('@/views/actciles'),
		meta: {
			title: '资讯'
		}
	},
	{ // 资讯详情
		path: '/actciles/:id/detail',
		component: () => import('@/views/actciles/detail'),
		meta: {
			title: '资讯详情'
		}
	},
	{ // 医院频道
		path: '/hospitalChannel',
		component: () => import('@/views/hospitalChannel'),
		meta: {
			title: '医院频道',
			onHover: true
		}
	},
	{ // 医院频道详情
		path: '/hospitalChannel/:id/detail',
		component: () => import('@/views/hospitalChannel/detail'),
		meta: {
			title: '医院详情',
			onHover: true
		}
	},
	{ // 合作伙伴
		path: '/partnership',
		component: () => import('@/views/partnership'),
		meta: {
			title: '合作伙伴',
			onHover: true
		}
	},
	{ // 关于我们
		path: '/aboutUs',
		component: () => import('@/views/aboutUs'),
		meta: {
			title: '关于我们',
			onHover: true
		}
	},
	{ // 加入我们
		path: '/joinUs',
		component: () => import('@/views/joinUs'),
		meta: {
			title: '加入我们',
			onHover: true
		}
	},
	{ // 搜索
		path: '/search/:keyword',
		component: () => import('@/views/search'),
		meta: {
			title: '搜索内容'
		}
	},
	/* 达人秀 */
	{
		path: '/lucky-draw',
		component: () => import('@/views/baiji/lucky-draw'),
		meta: {
			title: '抽奖页面',
			isTalent: true
		}
	},
	{
		path: '/result',
		component: () => import('@/views/baiji/result'),
		meta: {
			title: '结果页面',
			isTalent: true
		}
	},
	{
		path: '/result-list',
		component: () => import('@/views/baiji/result-list'),
		meta: {
			title: '统分页面',
			isTalent: true
		}
	},
	{
		path: '/star',
		component: () => import('@/views/baiji/star'),
		meta: {
			title: '百选之星',
			isTalent: true
		}
	},
	{
		path: '/win',
		component: () => import('@/views/baiji/win'),
		meta: {
			title: 'U秀达人',
			isTalent: true
		}
	},
	/* 达人秀2023年 */
	{
		path: '/baiji-23/result',
		component: () => import('@/views/baiji-23/result'),
		meta: {
			title: '结果页面',
			isTalent: true
		}
	},
	{
		path: '/baiji-23/speak-sequential',
		component: () => import('@/views/baiji-23/speak-sequential'),
		meta: {
			title: '抢答结果页面',
			isTalent: true
		}
	},
	{
		path: '/baiji-23/lucky-draw',
		component: () => import('@/views/baiji-23/lucky-draw'),
		meta: {
			title: '抽奖页面',
			isTalent: true
		}
	},
	{
		path: '/baiji-23/result-list',
		component: () => import('@/views/baiji-23/result-list'),
		meta: {
			title: '统分页面',
			isTalent: true
		}
	},
	/* 达人秀2024年 */
	{
		path: '/baiji-24/result',
		component: () => import('@/views/baiji-24/result'),
		meta: {
			title: '结果页面',
			isTalent: true
		}
	},
	{
		path: '/baiji-24/speak-sequential',
		component: () => import('@/views/baiji-24/speak-sequential'),
		meta: {
			title: '抢答结果页面',
			isTalent: true
		}
	},
	{
		path: '/baiji-24/lucky-draw',
		component: () => import('@/views/baiji-24/lucky-draw'),
		meta: {
			title: '抽奖页面',
			isTalent: true
		}
	},
	{
		path: '/baiji-24/result-list',
		component: () => import('@/views/baiji-24/result-list'),
		meta: {
			title: '统分页面',
			isTalent: true
		}
	},

	{
		path: '/celebrate',
		component: () => import('@/views/other/celebrate'),
		meta: {
			title: '周年庆抽奖',
			isTalent: true
		}
	},
	{
		path: '/research',
		component: () => import('@/views/other/research'),
		meta: {
			title: '调研抽奖',
			isTalent: true
		}
	},
	{
		path: '/research-draw',
		component: () => import('@/views/other/research-draw'),
		meta: {
			title: '直播间有奖调研',
			isTalent: true
		}
	},
	{
		path: '/research-draw2',
		component: () => import('@/views/other/research-draw2'),
		meta: {
			title: '直播间有奖调研',
			isTalent: true
		}
	},
	{
		path: '/winners',
		component: () => import('@/views/other/winners'),
		meta: {
			title: '中奖名单',
			isTalent: true
		}
	},
	{
		path: '/speak-sequential',
		component: () => import('@/views/baiji/speak-sequential'),
		meta: {
			title: '发言顺序页面',
			isTalent: true
		}
	},
	/* 调研统计 */
	{
		path: '/research-statistics/:id',
		component: () => import('@/views/other/research-statistics'),
		meta: {
			title: '调研统计',
			isTalent: true
		}
	},
	{ // 会议专题
		path: '/subject/:id',
		component: () => import('@/views/subject'),
		meta: {
			title: '会议专题'
		}
	},
	// 白泽安
	{
		path: '/bza/tweets',
		component: () => import('@/views/bza/tweets'),
		meta: {
			title: '病例分享',
			isTalent: true
		}
	},
	{
		path: '/bza/video',
		component: () => import('@/views/bza/video'),
		meta: {
			title: '精彩视频',
			isTalent: true
		}
	},
	{
		path: '/bza/operation',
		component: () => import('@/views/bza/operation'),
		meta: {
			title: '手术视频',
			isTalent: true
		}
	},
	{
		path: '/bza/operation/:id',
		component: () => import('@/views/bza/operation-details'),
		meta: {
			title: '手术视频详情',
			isTalent: true
		}
	},
	//  百洋专区
	{
		path: '/by/lucky-draw',
		component: () => import('@/views/baiyang/lucky-draw'),
		meta: {
			title: '抽奖页面',
			isTalent: true
		}
	},
	{
		path: '/by/result',
		component: () => import('@/views/baiyang/result'),
		meta: {
			title: '结果页面',
			isTalent: true
		}
	},
	{
		path: '/by/result-list',
		component: () => import('@/views/baiyang/result-list'),
		meta: {
			title: '统分页面',
			isTalent: true
		}
	},
	{
		path: '/by/speak-sequential',
		component: () => import('@/views/baiyang/speak-sequential'),
		meta: {
			title: '发言顺序页面',
			isTalent: true
		}
	},
	{
		path: '/by/random-draw',
		component: () => import('@/views/baiyang/random-draw'),
		meta: {
			title: '抽签分组',
			isTalent: true
		}
	},
	{
		path: '/by/random-drawZ',
		component: () => import('@/views/baiyang/random-drawZ'),
		meta: {
			title: '抽签总决赛',
			isTalent: true
		}
	},
	{
		path: '/cxds/result-playoff',
		component: () => import('@/views/cxds/result-playoff'),
		meta: {
			title: '晋级选手',
			isTalent: true
		}
	},
	{
		path: '/cxds/result',
		component: () => import('@/views/cxds/result'),
		meta: {
			title: '晋级选手',
			isTalent: true
		}
	},
	{
		path: '/cxds/result-cccz',
		component: () => import('@/views/cxds/result-cccz'),
		meta: {
			title: '初创成长组',
			isTalent: true
		}
	},
	{
		path: '/cxds/result-cxcy',
		component: () => import('@/views/cxds/result-cxcy'),
		meta: {
			title: '创新创意组',
			isTalent: true
		}
	},
	{
		path: '/cxds/result-list',
		component: () => import('@/views/cxds/result-list'),
		meta: {
			title: '晋级选手得分',
			isTalent: true
		}
	},
	
	
	// 2023年
	{
		path: '/cxds2023/result-playoff',
		component: () => import('@/views/cxds2023/result-playoff'),
		meta: {
			title: '晋级选手',
			isTalent: true
		}
	},
	{
		path: '/cxds2023/result',
		component: () => import('@/views/cxds2023/result'),
		meta: {
			title: '晋级选手',
			isTalent: true
		}
	},
	{
		path: '/cxds2023/result-cccz',
		component: () => import('@/views/cxds2023/result-cccz'),
		meta: {
			title: '初创成长组',
			isTalent: true
		}
	},
	{
		path: '/cxds2023/result-cxcy',
		component: () => import('@/views/cxds2023/result-cxcy'),
		meta: {
			title: '创新创意组',
			isTalent: true
		}
	},
	{
		path: '/cxds2023/result-list',
		component: () => import('@/views/cxds2023/result-list'),
		meta: {
			title: '晋级选手得分',
			isTalent: true
		}
	},
	{
		path: '*',
		component: () => import('@/views/404'),
		meta: {
			title: '404'
		}
	}
]

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop;
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})

export default router