export default {
    pcUuid:'/pc/pc/pcUuid', // 获取uuid
    LxUuid:'/pc/pc/LxUuid', // 轮询uuid
    Login: '/api/wechat/weChatAuthorization3',
    wx_Login: '/api/wechat/weChatAuthorization',
    homeData: '/pc/pc/index', // 首页数据
    // liveList:'/tools/live/index2', // 首页-直播课程
    // payCourseList:'/tools/live/courseList_charge', // 首页-付费课程
    // newCourseList: '/tools/live/courseList', //首页-最新课程
    // courseListHot: '/tools/live/courseListHot', //热门课程
    // information: '/tools/information/index', //首页-最新资讯
    classApi: '/service/service/classApi', // 分类
    tags: '/service/service/tags', // 筛选
    courseList: '/tools/live/courseList', // 手术视频列表
    getVideoUrl_One: '/tools/live/getVideoUrl_One', // 手术视频切换
    courseDetails: '/tools/live/courseDetails', // 手术视频详情 detail
    buyCourse: '/tools/live/buyCourse', //手术视频详情-立即报名
    course: '/tools/live/course', // 手术视频播放详情 play
    CourseCatalog: '/tools/live/CourseCatalog', // 手术视频播放 play
    chat2: '/tools/chat/chat2', // 手术视频播放 用户聊天列表
    setChat: '/tools/chat/setChat', // 手术视频播放 发送聊天

    courseList_charge: '/tools/live/courseList_charge', // 付费课程列表
	courseListChargeList: '/tools/live/courseListChargeList', // 会员课程列表

    index2: '/tools/live/index2', // 讲座会议列表
    details: '/tools/live/details', // 讲座会议详情
    room: '/tools/live/room', // 讲座会议播放
    liveEnroll: '/tools/live/liveEnroll', //讲座会议报名

    study_add: '/tools/live/study_add', //讲座会议报名


    information: '/tools/information/index', // 资讯列表
    informationHot: '/tools/information/indexHot', // 资讯列表热门
    informationDetails: '/tools/information/details', // 资讯详情
    information_tj: '/pc/pc/information_tj', // 资讯推荐
    researchRelation: '/tools/research/researchRelation', // 资讯调研
    researchRelation_form: '/tools/research/researchRelation_form', // 提交资讯调研

    hosptial_all: '/pc/pc/hosptial_all', // 所有医院
    hosptial_pro: '/pc/pc/hosptial_pro', // 市级医院
    hosptial_index: '/pc/pc/hosptial_index', // 医院详情
    join_us: '/pc/pc/join_us', // 加入我们

    searchIndex: '/pc/pc/searchIndex', // 搜索


    /*达人秀*/
    show_match_api: '/match/Baekje/show_match_api',
    luckDraw: '/match/Baekje/luckDraw',
    result: '/match/Baekje/result',
    result_self: '/match/Baekje/result_self',
    answerResult: '/match/Baekje/answerResult',
	
	/*达人秀2023年*/
    baiji23resultBattles: '/match/TalentShow/resultBattles',
	baiji23answerResult: '/match/TalentShow/answerResult',
	baiji23GroupStatus: '/match/TalentShow/groupStatus',
	baiji23luckDraw: '/match/TalentShow/luckDraw',
	baiji23ResultSelf: '/match/TalentShow/result_self',
	
    /* 百洋 */
    baiyangGroup: '/match/Baiyang/baiyangGroup',
    byAnswerResult: '/match/Baiyang/answerResult',
    byresult: '/match/Baiyang/result',
    byresult_self: '/match/Baiyang/result_self',
    byluckDraw: '/match/Baiyang/luckDraw',
	byjudgesOrderDraw: '/match/Baheal/judgesOrderDraw',
	playerDraw: '/match/Baheal/playerDraw',
	
	/* 创新大赛 */
	cxdsresult: '/match/Innovate/result',
	cxdsresult_self: '/match/Innovate/result_self',


    /* 周年庆抽奖 */
    luckDrawYear: '/match/Baekje/luckDrawYear', // 周年庆抽奖
    luckDrawSurvey: '/match/Baekje/luckDrawSurvey', // 调研抽奖
    luckDrawYear2: '/match/Baekje/luckDrawYear2', // 周年庆抽奖
    luckDrawSurvey2: '/match/Baekje/luckDrawSurvey2', // 调研抽奖
    luckDrawYear_All: '/match/Baekje/luckDrawYear_All', // 抽奖回显
    luckDrawYear_All2: '/match/Baekje/luckDrawYear_All2', // 抽奖回显
    delLuckDraw: '/match/Baekje/delLuckDraw', // 删除抽奖人


    research_list: '/books/Lb/research_list', // 调研统计
    pcSpecialCourseList: '/pc/pc/pcSpecialCourseList', // 会议专题


    is_alert: '/base/user/is_alert', //是否完善信息

    MatchTweetList: '/match/Baekje/MatchTweetList', //推文
    addMatchTweet_num: '/match/Baekje/addMatchTweet_num', //推文点击量
    MatchVideoList: '/match/Baekje/MatchVideoList', //精彩视频
    addMatchVideo_num: '/match/Baekje/addMatchVideo_num', //精彩视频点击量
    SurgicalQueryList: '/match/Baekje/SurgicalQueryList', //手术视频
    SurgicalQueryInfo: '/match/Baekje/SurgicalQueryInfo', //手术视频详情
    recordAll: '/base/user/recordAll', //记录访问人

    researchLuckDraw: '/tools/Research/researchLuckDraw', //调研抽奖
    researchLuckDrawNumber: '/tools/Research/researchLuckDrawNumber', //调研抽奖配置
    researchLuckDrawEnd: '/tools/Research/researchLuckDrawEnd' //调研抽奖配置
}
