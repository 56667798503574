<template>
  <div id="app">
    <template v-if="$route.meta.isTalent && $route.meta.title"><router-view/></template>
    <template v-if="!$route.meta.isTalent && $route.meta.title">
      <app-header/>
      <router-view/>
      <app-footer/>
      <app-fixed v-if="$route.path!='/fqj/2210/play'"/>
    </template>
    <app-wxlogin-dialog/>
  </div>
</template>
<script>
  import { is_wx,urlDelP } from '@/utils/index';
  import config from '@/api/url';
  export default {
    watch:{
      $route(to,from){
		let _this=this
        if(to.query.code) {
          let code = to.query.code;
          let msg = code;
          if(typeof code != 'string') {
            msg = code[code.length-1];
          }
		  setTimeout(function(){
			_this.$store.dispatch('toLogin', msg);
		  },1000)
        }
      }
    },
    computed: {
      userInfo() {
		  // console.log(this.$route.path)
        return this.$store.state.userInfo
      },
    },
	mounted() {
		// return location.href='https://miniapp.ysxxlm.com/upgrading.html'
		// if(sessionStorage.getItem('upgrading')!='1'){
		// 	sessionStorage.setItem('upgrading',1)
		// 	setTimeout(function() {
		// 		// window.open('https://miniapp.ysxxlm.com/upgrading.html')
		// 		location.href='https://miniapp.ysxxlm.com/upgrading.html'
		// 	}, 2000);
		// }
	},
    created() {
      let result = window.matchMedia("(min-width: 780px)").matches;
      if(!result) {
        location.href = config.baseUrl+ '/ysxxlm/index.html#/';
      }
      if(!is_wx()) {
        if(!this.userInfo || this.userInfo == undefined) {
          this.$store.dispatch('pcUuid')
        }else {
          this.$store.dispatch('LxUuid');
        }
      }else { // 微信浏览器自动登录
        if((this.userInfo=='' || this.userInfo == undefined)&& !this.$route.query.code){
          let url = urlDelP(window.location.href,'code');
          let readApi = 'https://miniapp.ysxxlm.com/api/Wechat/weChatAuthorization'
          let local = encodeURIComponent(url); //获取当前页面地址
          let redirect_uri = encodeURIComponent(readApi)
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc7dec105cbf00538&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=" +
                  local + "&connect_redirect=1" + "#wechat_redirect";
        }
      }
    }
  }
</script>
<style lang="scss">
  @import url("//at.alicdn.com/t/font_2540755_9msbpfkweo5.css");
  .modal-txt{
    text-align: center;
    img{width: 200px;}
    p{color: #999;font-size: 12px;}
  }
</style>
