import appHeader from './app-header';// 头部
import appFixed from './app-fixed';// 头部
import appFooter from './app-footer';// 头部
import appWxloginDialog from './app-wxlogin-dialog';// 头部
import plateTitle from './plate-title';// 标题
import liveCourseItem from './live-course-item';// 直播课程item
import payCourseItem from './pay-course-item';// 收费课程item
import hotCourseItem from './hot-course-item';// 热门课程item
import vipCourseItem from './vip-course-item';// vip课程item
import liveListCourseItem from './hot-course-item/liveList';// 直播item
import seeMore from './see-more';// 查看更多点击
import appMenu from './app-menu';// 分类
import vipMenu from './vip-menu';// 会员课程分类
import hospitalTitle from './hospital-title';// 分类
import noData from './no-data';// 无数据提示
const globalComponents = {
    install: Vue => {
        Vue.component('app-header', appHeader)
        Vue.component('app-fixed', appFixed)
        Vue.component('app-footer', appFooter)
        Vue.component('app-wxlogin-dialog', appWxloginDialog)
        Vue.component('plate-title', plateTitle)
        Vue.component('live-course-item', liveCourseItem)
        Vue.component('pay-course-item', payCourseItem)
        Vue.component('hot-course-item', hotCourseItem)
		Vue.component('vip-course-item', vipCourseItem)
        Vue.component('live-list-item', liveListCourseItem)
        Vue.component('see-more', seeMore)
        Vue.component('app-menu', appMenu)
		Vue.component('vip-menu', vipMenu)
        Vue.component('hospital-title', hospitalTitle)
        Vue.component('no-data', noData)
    }
}
export default globalComponents;
