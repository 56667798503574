<template>
    <div class="course-item">
        <div class="course-item-img">
            <img class="course-item-img-master" :src="item.banner"/>
            <div class="course-item-img-num">{{ item.study_count }}人学过</div>
            <div class="home-hotCourse-vip" v-if="item.is_vip">会员课程</div>
        </div>
        <div class="course-item-title">{{ item.title }}</div>
        <div class="course-item-price">
            <div class="course-item-price-author">{{ item.author }}</div>
            <div class="course-item-price-l">
<!--                <span style="font-size: 18px" v-if="item.event_price">{{ item.event_price }}</span>-->
<!--                <span :style="item.event_price?'text-decoration: line-through;color: #999;font-weight: normal':''">{{ item.price == '0.00'?'免费':item.price }}</span>-->
                <span>{{ item.price == '0.00'?'免费':item.price }}</span>
            </div>
<!--            <div class="course-item-price-r" v-if="item.is_vip">会员课程</div>-->
        </div>
        <div class="course-item-hospital">{{ item.company }}</div>
        <!--<div class="course-item-price">￥{{ item.price }}</div>-->


    </div>
</template>

<script>
    export default {
        props: ['item']
    }
</script>

<style scoped lang="scss">
    .course-item{
        width: 210px;
        padding: 5px 10px 0;
        .course-item-img{
            height: 118px;
            vertical-align: top;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            .course-item-img-sign{
                position: absolute;
                left: 0;
                top: 0;
                width: 90px;
                height: 28px;
            }
            .course-item-img-master{
                height: 100%;
                width: 100%;
                vertical-align: top;
            }
            .course-item-img-num{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 28px;
                width: 145px;
                line-height: 28px;
                text-align: right;
                color: #fff;
                font-size: 14px;
                background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/course-ombre@2x.png") no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding-right: 14px;
            }
            .home-hotCourse-vip{
                position: absolute;
                left: 0;
                top: 0;

                height: 28px;
                line-height: 26px;
                padding: 0 15px;
                background: #288cfb;
                color: #fff;
                font-size: 14px;
                border-bottom-right-radius: 5px;
            }
        }
        .course-item-title{
            color: #090B11;
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            vertical-align: top;
            margin-top: 5px;
            font-weight: 500;
        }
        .course-item-hospital{
            color: #858585;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            vertical-align: top;
        }
        .course-item-price{
            color: #409AFF;
            font-size: 16px;
            height: 20px;
            line-height: 20px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            vertical-align: top;
            margin-top: 5px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                margin-right: 5px;
            }
            .course-item-price-l{
            }
            .course-item-price-r{
                padding: 0 2px;
                border: 1px solid #b1894e;
                border-radius: 8px;
                font-size: 10px;
                font-weight: 400;
                color: #b78842;
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                margin-left: 8px;
                height: 16px;
            }
            .course-item-price-author{
                color: #858585;
                font-weight: normal;
                font-size: 12px;
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex: 1;
            }
        }
        &:hover{
            box-shadow: 0 0 10px #ccc;
            border-radius: 5px;
            cursor: pointer;
            .course-item-img{
                .course-item-img-master{
                    transition: 1s;
                    transform: scale(1.1);
                }
            }
        }
    }
</style>
