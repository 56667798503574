<template>
    <div class="see-more" :class="about?'see-more-about':''" @click="toMore">查看更多 &gt;</div>
</template>

<script>
    export default {
        props: {
            path: {
                type: String,
                default: ''
            },
            about: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            toMore() {
                this.$router.push({
                    path: this.path
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .see-more{
        width: 160px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #E6F2FF;
        margin: 0 auto;
        font-size: 16px;
        color: #288CFB;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
            background: #409AFF;
            color: #fff;
        }
        &.see-more-about{
            background: none;
            border: 1px solid #fff;
            color: #fff;
            &:hover{
                background: #409AFF;
                color: #fff;
            }
        }
    }
</style>
