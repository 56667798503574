<template>
    <div class="footer">
        <div class="footer-main">
            <!-- <div class="footer-left">版权所有 © 2016-{{ new Date().getFullYear() }} 泌尿外科医生学习联盟 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;">湘ICP备13012229号-2</a></div> -->
			<div class="footer-left">版权所有 © 2016-{{ new Date().getFullYear() }}湖南优安医学科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;">湘ICP备13012229号-2</a></div>
            <div class="footer-right">互联网药品信息服务资格证书编号：(湘)-非经营性-2019-0078</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>

</style>
