<template>
    <div class="float-fixed" :style="{'right': fixedRight+'px'}">
        <div class="float-fixed-item">
            <router-link to="/courseVip"><div class="float-fixed-item-name">会员课</div></router-link>
        </div>
        <div class="float-fixed-item">
            <div class="float-fixed-item-name">移动版</div>
            <div class="float-fixed-item-ewm">
                <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/erweima.jpg">
                <p>扫一扫了解更多</p>
            </div>
        </div>
        <div class="float-fixed-item">
            <div class="float-fixed-item-name">小程序</div>
            <div class="float-fixed-item-ewm">
                <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/xcx.jpg">
                <p>扫一扫了解更多</p>
            </div>
        </div>
        <!--<div class="float-fixed-item">
          <div class="float-fixed-item-name">医疗器械</div>
          <div class="float-fixed-item-ewm">
            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/xcx.jpg">
            <p>扫一扫了解更多</p>
          </div>
        </div>-->
        <div class="float-fixed-item">
            <div class="float-fixed-item-name" :style="scrollTop<=150?{'border-bottom': 0}:''">指&nbsp;&nbsp;&nbsp;南</div>
            <div class="float-fixed-item-ewm">
                <img src="@/assets/otherimg/xcx.jpg">
                <p>扫一扫了解更多</p>
            </div>
        </div>
        <div class="float-fixed-item" @click="backTop" v-if="scrollTop>150">
            <div class="float-fixed-item-top">
                <i class="iconfont icon-TOP"></i>
                <!--<img src="./assets/img/back-top@2x.png"/>-->
                <p>回到顶部</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "app-fixed",
        data() {
            return {
                scrollTop: 0,
                fixedRight: 0,
            }
        },
        mounted() {
            window.addEventListener("scroll",this.showbtn,true);
            this.loadFixedRight();
            window.onresize = () => {
                return (() => {
                    this.loadFixedRight();
                })()
            }
        },
        methods: {
            loadFixedRight() {
                window.screenWidth = document.body.clientWidth;
                let screenWidth = window.screenWidth;
                let boxWidth = 1200;
                let leftW = boxWidth + ((screenWidth-boxWidth)/2);
                if(screenWidth<=boxWidth+160) {
                    this.fixedRight = 0
                }else if(screenWidth>=leftW-150) {
                    this.fixedRight = (screenWidth-leftW-150)>=0?(screenWidth-leftW-150):0
                }
            },
            showbtn(){
                let that = this;
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                that.scrollTop = scrollTop;
            },
            backTop() {
                var timer = setInterval(function(){
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5);
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if(osTop === 0){
                        clearInterval(timer);
                    }
                },30)
            }
        }
    }
</script>

<style scoped>

</style>
