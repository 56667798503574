<template>
    <div class="header">
        <template v-if="$route.path == '/'">
            <div class="app-header">
                <div class="app-header-main">
                    <div class="app-header-top">
                        <router-link to="/" class="app-header-logo">
                            <!-- <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/logo@3x.jpg"/> -->
							<img src="~@/assets/img/logo@3x.png"/>
                        </router-link>
                        <div class="app-header-search">
                            <div class="header-search-main">
                                <div class="header-search-icon"><i class="iconfont icon-sousuo"></i></div>
                                <div class="header-search-text">
                                    <input type="text" placeholder="请输入关键字搜索" v-model="keyword" @keyup.enter="search">
                                    <i class="header-search-close el-icon-circle-close" v-if="keyword" @click="keyword = ''"></i>
                                </div>
                                <div class="header-search-btn" @click="search">搜索</div>
                            </div>
                        </div>
                        <div class="app-header-login" @click="wxLogin" v-if="!userInfo || this.userInfo == undefined">
                          <i class="iconfont icon-02"></i> 登录 | 注册
                        </div>
                        <div class="app-header-head" v-else-if="userInfo.user">
                            <div class="app-header-head-img">
                                <img :src="userInfo.user.face"/><i class="iconfont icon-xiala"></i>
                            </div>
                            <div class="app-header-head-exit" v-if="!is_wx">
                                <div class="app-header-head-exit-name">{{ userInfo.user.nickname }}</div>
                                <div class="app-header-head-exit-btn" @click="logOut">退出登录</div>
                            </div>
                        </div>
                    </div>

                    <div class="app-header-nav">
                        <router-link to="/" exact>首页</router-link>
                        <router-link to="/lct">手术视频</router-link>
                        <router-link to="/courseVip">会员课程</router-link>
						<!-- <router-link to="/course">培训课程</router-link> -->
                        <router-link to="/seminars">讲座会议</router-link>
                        <router-link to="/actciles">资讯</router-link>
                        <!-- <router-link to="/hospitalChannel">医院频道</router-link> -->
						<div class="other-header-nav-more">
							<div class="other-header-nav-more-title" :class="$route.meta.onHover?'on':''">
								大赛活动
								<i class="iconfont icon-xiala"></i>
							</div>
							<div class="other-header-nav-more-other">
								<a href="https://cxds.ysxxlm.com">创新大赛</a>
								<a href="https://bpds.ysxxlm.com">包皮大赛</a>
								<a href="https://bph.ysxxlm.com">前列腺增生手术大赛</a>
								<a href="https://sui.ysxxlm.com/">尿失禁手术大赛</a>
							</div>
						</div>
						<router-link to="/lct/2210/detail">模拟器练习</router-link>
                        <router-link to="/partnership">合作伙伴</router-link>
                        <router-link to="/joinUs">加入我们</router-link>
                        <router-link to="/aboutUs">关于我们</router-link>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="other-header">
                <div class="other-header-main">
                    <div class="other-header-left">
                        <div class="other-header-logo">
							<router-link to="/">
								<!-- <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/logo@2x-2.png"> -->
								<img src="~@/assets/img/logo@3x.png">
							</router-link>
						</div>
                        <div class="other-header-nav">
                            <router-link class="other-header-nav-item" to="/" exact>首页</router-link>
                            <router-link class="other-header-nav-item" to="/lct">手术视频</router-link>
							<router-link class="other-header-nav-item" to="/courseVip">会员课程</router-link>
                            <!-- <router-link class="other-header-nav-item" to="/course">培训课程</router-link> -->
                            <router-link class="other-header-nav-item" to="/seminars">讲座会议</router-link>
                            <router-link class="other-header-nav-item" to="/actciles">资讯</router-link>
                            <div class="other-header-nav-more">
                                <div class="other-header-nav-more-title" :class="$route.meta.onHover?'on':''">
                                    更多
                                    <i class="iconfont icon-xiala"></i>
                                </div>
                                <div class="other-header-nav-more-other">
                                    <!-- <router-link to="/hospitalChannel">医院频道</router-link> -->
									<a href="https://cxds.ysxxlm.com/#/">创新大赛</a>
									<a href="https://bpds.ysxxlm.com">包皮大赛</a>
									<a href="https://bph.ysxxlm.com">前列腺增生手术大赛</a>
									<a href="https://sui.ysxxlm.com/">尿失禁手术大赛</a>
									<router-link to="/lct/2210/detail">模拟器练习</router-link>
                                    <router-link to="/partnership">合作伙伴</router-link>
                                    <router-link to="/joinUs">加入我们</router-link>
                                    <router-link to="/aboutUs">关于我们</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="other-header-search">
                            <i class="iconfont icon-sousuo" @click="search"></i>
                            <input type="text" v-model="keyword" placeholder="请输入关键字搜索" @keyup.enter="search"/>
                            <i class="other-header-search-close el-icon-circle-close" v-if="keyword" @click="clearKeyword"></i>
                        </div>
                    </div>
                    <div class="app-header-login" @click="wxLogin" v-if="!userInfo || this.userInfo == undefined">
                        <i class="iconfont icon-02"></i> 登录 | 注册
                    </div>
                    <div class="other-header-head" v-else-if="userInfo.user">
                        <div class="other-header-head-img">
                            <img :src="userInfo.user.face"/><i class="iconfont icon-xiala"></i>
                        </div>
                        <div class="other-header-head-exit" v-if="!is_wx">
                            <div class="other-header-head-exit-name">{{ userInfo.user.nickname }}</div>
                            <div class="other-header-head-exit-btn" @click="logOut">退出登录</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Search from '@/utils/search'
    import { is_wx,urlDelP } from '@/utils/index';
    export default {
        data() {
            return {
                keyword: '',
                is_wx: is_wx()
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            },
        },
        methods: {
            wxLogin() {
                if(is_wx()) {
                    let url = urlDelP(window.location.href,'code');
                    let readApi = 'https://miniapp.ysxxlm.com/api/Wechat/weChatAuthorization'
                    let local = encodeURIComponent(url); //获取当前页面地址
                    let redirect_uri = encodeURIComponent(readApi)
                    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc7dec105cbf00538&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=" +
                        local + "&connect_redirect=1" + "#wechat_redirect";
                }else {
                    this.$store.commit('wxLogin', true);
                }
            },
            logOut() {
                this.$store.commit('logOut')
            },
            clearKeyword() {
                this.keyword = '';
                Search.$emit('search', {
                    keyword: ''
                })
            },
            search() {
                if(this.keyword == "") {
                    this.$notify({
                        title: '提示',
                        message: '请输入搜索内容',
                        type: 'warning'
                    });
                    return false
                }
                if(this.keyword == null || this.keyword == undefined) {
                    this.$notify({
                        title: '提示',
                        message: '请输入正确的搜索内容',
                        type: 'warning'
                    });
                    return false
                }

                let paths = ['/lct','/course','/seminars','/actciles'];
                //console.log(paths.includes(this.$route.path))
                if(paths.includes(this.$route.path)) {
                    Search.$emit('search', {
                        keyword: this.keyword
                    })
                }else {
                    if(this.$route.path.search('/search') == -1){
                        this.$router.push(`/search/${this.keyword}`)
                    }else {
                        this.$router.replace(`/search/${this.keyword}`)
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
