<template>
    <div class="home-hotCourse-item">
        <div class="home-hotCourse-img">
            <img :src="item.banner"/>
            <div class="home-hotCourse-num">{{ item.study_count }}人学过</div>
			
			<div class="live-list-item-label">
				<div class="label-icon" v-if="item.pid==155">
					<i class="iconfont icon-play"></i> 直播回放
				</div>
			</div>

            <!-- <div class="home-hotCourse-vip" v-if="item.is_vip">会员课程</div>
			<div class="live-list-item-label" v-else>
				<div class="label-icon" v-if="item.pid==155">
					<i class="iconfont icon-play"></i> 直播回放
				</div>
			</div> -->
        </div>
        <div class="home-hotCourse-title">
            {{ item.title }}
        </div>
        <div class="home-hotCourse-price">
            <div class="course-item-price-author">{{ item.author }}</div>
            <div class="course-item-price-l">
                <span style="font-size: 18px" v-if="item.event_price!=item.price">{{ item.event_price }}</span>
                <span v-if="item.event_price!=item.price" :style="item.event_price?'text-decoration: line-through;color: #999;font-weight: normal':''">{{ item.price == '0.00'?'免费':item.price }}</span>
                <span v-else>{{ item.price == '0.00'?'免费':item.price }}</span>
            </div>
        </div>
        <div class="home-hotCourse-hospital">
            {{ item.company }}
        </div>

    </div>
</template>

<script>
    export default {
        props: ['item']
    }
</script>

<style scoped lang="scss">
    .home-hotCourse-item{
        width: 240px;
        height: 255px;
        border-radius: 5px;
        padding: 15px 15px 0;
        box-sizing: border-box;
        overflow: hidden;
        .home-hotCourse-img{
            height: 118px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            img{
                width: 100%;
                height: 118px;
            }
            .home-hotCourse-num{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 28px;
                width: 145px;
                line-height: 28px;
                text-align: right;
                color: #fff;
                font-size: 14px;
                background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/course-ombre@2x.png") no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding-right: 14px;
            }
            .home-hotCourse-vip{
                position: absolute;
                left: 0;
                top: 0;
                height: 28px;
                line-height: 26px;
                padding: 0 15px;
                background: #288cfb;
                color: #fff;
                font-size: 14px;
                border-bottom-right-radius: 5px;
            }
			.live-list-item-label{
			    position: absolute;
			    top: 0;
			    left: 0;
			    img{
			        height: 28px;
			        width: auto;
			    }
			    .label-icon{
			        height: 28px;
			        line-height: 26px;
			        padding: 0 15px;
			        background: #288cfb;
			        color: #fff;
			        font-size: 14px;
			        border-bottom-right-radius: 5px;
			        i{
			            font-size: 12px;
			        }
			    }
			}
        }
        .home-hotCourse-title{
            margin-top: 10px;
            font-size: 16px;
            color: #090B11;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 25px;
            height: 50px;
            font-weight: 500;
        }
        .home-hotCourse-hospital{
            font-size: 14px;
            color: #858585;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .home-hotCourse-price{
            font-size: 16px;
            color: #288CFB;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                margin-right: 5px;
            }
            .course-item-price-author{
                color: #858585;
                font-weight: normal;
                font-size: 12px;
                max-width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex: 1;
            }
            .course-item-price-l{
                /*flex: 1;*/
                text-align: right;
            }
            .course-item-price-r{
                padding: 0 2px;
                border: 1px solid #b1894e;
                border-radius: 8px;
                font-size: 10px;
                font-weight: 400;
                color: #b78842;
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                align-items: center;
                margin-left: 8px;
                height: 16px;
            }

        }
        &:hover{
            box-shadow: 0 0 20px #eee;
            cursor: pointer;
            background: #fff;
        }
    }
</style>
