let baseUrl = "";
let Appid = "";
/*baseUrl = 'https://test.ysxxlm.com';
Appid = 'wxa4cafe8d499fbe2b';*/
baseUrl = 'https://miniapp.ysxxlm.com';

// if (process.env.NODE_ENV === 'development') {
//     baseUrl = './';
// }

Appid = 'wx518d57f499ef3244';
export default {
    baseUrl,
    Appid
}
