<template>
    <div class="plate-title" :class="about?'plate-title-about':''">
        <h3>{{ title }}<span v-if="explain">{{ explain }}</span></h3>
        <p>{{ desc }}</p>
    </div>
</template>

<script>
    export default {
        //props: ['title','desc'],
        props: {
            title: {
                type: String,
                default: ''
            },
            desc: {
                type: String,
                default: ''
            },
            explain: {
                type: String,
                default: ''
            },
            about: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped lang="scss">
    .plate-title{
        text-align: center;
        position: relative;
        padding-bottom: 10px;
        min-width: 1200px;
        h3{
            color: #252A33;
            font-size: 28px;
            font-weight: 600;
            span{
                font-size: 20px;
                color: #A8A8A8;
                font-weight: normal;
            }
        }
        p{
            color: #A8A8A8;
            font-size: 18px;
        }
        &:after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 54px;
            height: 4px;
            background: #288CFB;
            margin-left: -27px;
        }
        &.plate-title-about{
            h3{
                color: #fff;
            }
            p{
                color: #fff;
            }
            &:after{
                background: #fff;
            }
        }
    }
</style>
