/**
 * 生成基础axios对象，并对请求和响应做处理
 * 前后端约定接口返回解构规范
 * {
 *    code:0,
 *    data:"成功",
 *    message:""
 * }
 */
import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import url from './url'
// 创建一个独立的axios实例
const service = axios.create({
    // 设置baseUr地址,如果通过proxy跨域可直接填写base地址
    baseURL: `${url.baseUrl}/`,
    // 定义统一的请求头部
    // 配置请求超时时间
    timeout: 30000,
    // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
    withCredentials: true
});
// 请求拦截
service.interceptors.request.use(config => {
    // 自定义header，可添加项目token
	if(store.state.token){
		config.headers['X-token'] = store.state.token;
	}
    return config;
});
// 返回拦截
service.interceptors.response.use((response)=>{
    // 获取接口返回结果
    const res = response.data;
    //console.log(res)
    return res;
},()=>{
    // Message.error('网络请求异常，请稍后重试!');
});
export default service;
