<template>
    <div class="hospital-item-title">
        <div class="hospital-item-title-name">{{ title }}</div>
        <div class="hospital-item-title-more" @click="toPath()"><span>查看更多<i class="iconfont icon-jiantou"></i></span></div>
    </div>
</template>

<script>
    export default {
        props: ['title','href'],
        methods: {
            toPath() {
                this.$router.push(this.href)
            }
        }
    }
</script>

<style scoped lang="scss">
    .hospital-item-title{
        display: flex;
        align-items: center;
        .hospital-item-title-name{
            flex: 1;
            color: #252A33;
            font-size: 28px;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 55px;
                height: 4px;
                background: #288CFB;
            }
        }
        .hospital-item-title-more{
            width: 160px;
            height: 40px;
            font-size: 16px;
            color: #288CFB;
            background: #E6F2FF;
            border-radius: 35px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
        }
    }
</style>
