<template>
    <el-dialog :visible.sync="loginDialog" width="400px" :before-close="loginClose">
        <div class="modal-txt">
            <div id="wx_login_id" class="login"></div>
            <div class="promptBox"></div>
            <p>请使用微信扫描二维码登录</p>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "app-wxlogin-dialog",
        computed: {
            loginDialog() {
                return this.$store.state.loginDialog
            },
        },
        methods: {
            loginClose() {
                this.$store.commit('wxLogin', false)
            }
        }
    }
</script>

<style scoped>

</style>
