<template>
    <div class="home-hotCourse-item">
        <div class="home-hotCourse-img">
            <div class="live-list-item-label">

                <div class="label-icon">
                    <i class="iconfont icon-play"></i> {{ item.is_live == 1 && item.live_state == 2?'正在直播':item.is_live == 1 && item.live_state==1?'等待直播':item.is_live == 1 && item.live_state == 4?'直播回放':item.is_live == 1 && item.live_state == 3?'直播已结束':item.is_live == 2?'课程':'' }}
                </div>

<!--                <div class="label-icon" v-if="item.is_live == 2"><i class="iconfont icon-play"></i> 课程</div>-->
<!--                <template v-if="item.is_live == 1">-->


<!--                    <img :src="item.live_state==2?'https://image.ysxxlm.cn/ysxxlm/pc/static/img/live/1@2x.png':item.live_state==1?'https://image.ysxxlm.cn/ysxxlm/pc/static/img/live/2@2x.png':'https://image.ysxxlm.cn/ysxxlm/pc/static/img/live/3@2x.png'"/>-->
<!--                </template>-->

            </div>
            <img :src="item.image"/>
            <!--<div class="home-hotCourse-num">{{ item.study_count }}人学过</div>-->
        </div>
        <div class="home-hotCourse-title">
            {{ item.title }}
        </div>
        <div class="home-hotCourse-hospital">
            {{ item.company || item.start_time }}
        </div>
        <div class="home-hotCourse-price">
            <span style="font-size: 20px" v-if="item.event_price">{{ item.event_price }}</span>
            <span :style="item.event_price?'text-decoration: line-through;color: #999;font-weight: normal':''">{{ item.price == '0.00'?'免费':item.price }}</span>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['item']
    }
</script>

<style scoped lang="scss">
    .home-hotCourse-item{
        width: 240px;
        height: 255px;
        border-radius: 5px;
        padding: 15px 15px 0;
        box-sizing: border-box;
        overflow: hidden;
        .home-hotCourse-img{
            height: 118px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            img{
                width: 100%;
                height: 118px;
            }
            .live-list-item-label{
                position: absolute;
                top: 0;
                left: 0;
                img{
                    height: 28px;
                    width: auto;
                }
                .label-icon{
                    height: 28px;
                    line-height: 26px;
                    padding: 0 15px;
                    background: #288cfb;
                    color: #fff;
                    font-size: 14px;
                    border-bottom-right-radius: 5px;
                    i{
                        font-size: 12px;
                    }
                }
            }
            .home-hotCourse-num{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 28px;
                width: 145px;
                line-height: 28px;
                text-align: right;
                color: #fff;
                font-size: 14px;
                background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/course-ombre@2x.png") no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding-right: 14px;
            }
        }
        .home-hotCourse-title{
            margin-top: 10px;
            font-size: 16px;
            color: #090B11;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 25px;
            height: 50px;
        }
        .home-hotCourse-hospital{
            font-size: 14px;
            color: #858585;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }
        .home-hotCourse-price{
            font-size: 18px;
            color: #288CFB;
            font-weight: 600;
            span {
                margin-right: 20px;
            }
        }
        &:hover{
            box-shadow: 0 0 20px #eee;
            cursor: pointer;
            background: #fff;
        }
    }
</style>
